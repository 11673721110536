var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"grey lighten-2 d-flex justify-center"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])})],1),(_vm.isLoadingData)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.inspection && _vm.register)?_c('v-container',[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('h1',{staticClass:"text-h6 primary--text",domProps:{"textContent":_vm._s(_vm.inspection && _vm.inspection.code)}}),_c('h2',{staticClass:"text-body-2 mb-0",domProps:{"textContent":_vm._s(_vm.inspection && _vm.textTransform(_vm.inspection.name))}})]),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.validateHeader() || _vm.isLoadingSave,"loading":_vm.isLoadingDraft},on:{"click":function($event){return _vm.send('DRAFT')}}},[_vm._v(" Guardar como borrador ")])],1)],1),(_vm.register)?_c('responsible-inpection',{attrs:{"register":_vm.register}}):_vm._e(),(_vm.inspection)?_c('v-card',{staticClass:"my-8",attrs:{"elevation":"2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"px-6"},[_c('validation-alert',{attrs:{"validationErrors":_vm.validationErrors || []}})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"pb-0",attrs:{"elevation":"0","vertical":"","value":_vm.steps.current},model:{value:(_vm.steps.current),callback:function ($$v) {_vm.$set(_vm.steps, "current", $$v)},expression:"steps.current"}},[_c('v-stepper-step',{attrs:{"complete":"","step":"1","edit-icon":_vm.validateHeader()
									? 'mdi-check'
									: 'mdi-alert-circle-outline ',"editable":"","color":_vm.steps.current == 1
									? 'primary'
									: _vm.validateHeader()
									? 'success'
									: 'error'}},[_vm._v(" Inicio ")]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('form-header',{attrs:{"headers":_vm.inspection.headers,"disabled":_vm.register && _vm.register.status != 'DRAFT'}}),_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary"},on:{"click":function($event){_vm.steps.current = 2}}},[_vm._v(" Siguiente ")])],1),_vm._l((_vm.inspection.bodies),function(group,i){return [_c('v-stepper-step',{key:i + 2,attrs:{"complete":"","step":i + 2,"edit-icon":_vm.validateGroup(i)
										? 'mdi-check'
										: 'mdi-alert-circle-outline ',"editable":"","color":'success'}},[_vm._v(" "+_vm._s(_vm.textTransform(group.group))+" ")]),(
									_vm.inspection &&
									_vm.inspection.bodies &&
									Array.isArray(_vm.inspection.bodies)
								)?_c('v-stepper-content',{key:i + 2 + 'vsc',attrs:{"step":i + 2}},[_c('v-card',{staticClass:"pt-4 pb-8",attrs:{"elevation":0}},[_vm._l((group.items),function(item,index){return [_c('v-row',{key:index,attrs:{"align":"center","justify":"space-between","dense":""}},[_c('v-col',{class:{ 'mt-5': index > 0 },attrs:{"cols":"12","sm":"8"}},[_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(item.description))])]),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-radio-group',{staticClass:"ml-0 ml-md-5 mt-2 p-0 mt-md-0 mb-1 mb-md-0",attrs:{"value":item.value,"row":"","hide-details":"","disabled":_vm.isDisabled()},on:{"change":function (value) {
															_vm.setValueRadio(
																value,
																i,
																index
															);
														}}},_vm._l((group.options_answer),function(option,iO){return _c('v-radio',{key:'radio-' + iO,attrs:{"label":option.label,"value":option.value,"color":option.color}})}),1)],1),(item.value == 'NO')?_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Detalle la(s) observacion(es)","rows":"3","hide-details":"","outlined":"","disabled":_vm.register.status != 'DRAFT'},on:{"input":function (value) {
															_vm.setValueObs(
																value,
																i,
																index
															);
														}},model:{value:(item.observations),callback:function ($$v) {_vm.$set(item, "observations", $$v)},expression:"item.observations"}})],1):_vm._e()],1),(index < group.items.length - 1)?_c('v-divider',{key:index + 'hr',staticClass:"hidden-sm-and-down mt-5"}):_vm._e()]})],2),(
										_vm.steps.current < _vm.inspection.bodies.length + 1
									)?_c('v-btn',{staticClass:"mb-3 mt-10",attrs:{"color":"primary"},on:{"click":function($event){_vm.steps.current = i + 3}}},[_vm._v(" Siguiente ")]):_vm._e()],1):_vm._e()]})],2)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-12 px-6",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center flex-wrap",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-body-2 font-weight-bold mr-5"},[_vm._v(" Valor Total: ")]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('v-alert',{staticClass:"pa-2 mr-2",attrs:{"text":"","outlined":"","color":"info"},domProps:{"textContent":_vm._s(
											'Criterios Aplicados: ' + _vm.totalItemsApply
										)}}),_vm._l((_vm.optionsAnswer),function(optionA,indexOA){return _c('v-alert',{key:'op-' + indexOA,staticClass:"pa-2 mr-2",attrs:{"text":"","outlined":"","color":optionA.color},domProps:{"textContent":_vm._s(
											_vm.getLabel(optionA.value) +
											': ' +
											optionA.total
										)}})})],2)]),_c('v-col',{staticClass:"d-flex align-center flex-wrap",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-body-2 font-weight-bold mr-5"},[_vm._v(" Porcentaje de cumplimiento: ")]),_c('v-alert',{staticClass:"pa-2",attrs:{"text":"","outlined":"","color":"info"},domProps:{"textContent":_vm._s(
										parseFloat(
											parseFloat(
												(
													(100 * _vm.register.items_yes) /
													(_vm.register.items_yes +
														_vm.register.items_no)
												).toString()
											).toFixed(0)
										) + '%'
									)}})],1),_c('hr',{staticClass:"w-100"}),_c('v-col',{attrs:{"cols":"12 d-flex justify-end flex-wrap"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"grey lighten-3"},on:{"click":function($event){return _vm.backPage()}}},[_vm._v(" Regresar ")]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","disabled":!_vm.validateHeader() || _vm.isLoadingSave,"loading":_vm.isLoadingDraft},on:{"click":function($event){return _vm.send('DRAFT')}}},[_vm._v(" Guardar como borrador ")]),(
										_vm.register.status == 'DRAFT' &&
										_vm.$route.name == 'InspectionsRegisterDraft'
									)?_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"green","loading":_vm.isLoadingSave,"disabled":!_vm.completedQuestionary || _vm.isLoadingDraft},on:{"click":function($event){return _vm.send('PENDING')}}},[_vm._v(" Finalizar ")]):_vm._e()],1)],1)],1)],1)],1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }